import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { arweave, getTopicString } from "../lib/api";

export const Backend = (props) => {
  return (
    <div id="i16r" class="cell">
      <form class="form">
        <div class="form-group">
          <label class="label">
            WeaveTrack Title :                              
          </label>
          <input
            type="email"
            placeholder="Type here your Title"
            class="input"
          />
        </div>
        <div class="textarea">
          <label class="label">
            Weave Track Description:                   
          </label>
          <input placeholder="Type Description here" class="input" />
        </div>
        <div class="form-group">
          <label class="label">Upload PDF:                    </label>
          <button class="button">Select Files</button>
        </div>
        <div class="form-group">
          <label class="label">Upload TXT:                    </label>
          <button class="button">Select Files</button>
        </div>
        <div class="form-group">
          <label class="label">Upload MP3:                    </label>
          <button class="button">Select Files</button>
        </div>
        <div class="form-group">
          <label class="label">Upload Video:                  </label>
          <button class="button">Select Files</button>
        </div>
        <div class="c6458">Select Era:</div>
        <select name="Select Era" class="select">
          <option value="">- Select option -</option>
          <option value="1">Option 1</option>
        </select>
      </form>
    </div>
  );
};
