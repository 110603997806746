import React from "react";
import {
  Routes,
  Route,
  Outlet,
  useParams,
  useNavigate,
} from "react-router-dom";
import { Navigation } from "./components/Navigation";
import { WalletSelectButton } from "./components/WalletSelectButton";
import { Posts } from "./components/Posts";
import { ProgressSpinner } from "./components/ProgressSpinner";
import { TopicSearch } from "./components/TopicSearch";
import { Main } from "./components/CurrentWeaveTrack";
import { UserSearch } from "./components/UserSearch";
import { Backend } from "./components/Backend";
import "./App.css";

async function getPostInfos() {
  return [];
}

const App = () => {
  const [isWalletConnected, setIsWalletConnected] = React.useState(false);
  const [postInfos, setPostInfos] = React.useState([]);
  const [isSearching, setIsSearching] = React.useState(false);

  React.useEffect(() => {
    getPostInfos();
  }, []);

  return (
    <div id="app">
      <div id="content">
        <aside>
          <WalletSelectButton
            setIsConnected={(value) => setIsWalletConnected(value)}
          />
          <Navigation />
        </aside>
        <main>
          <Routes>
            <Route path="/" name="home" element={<Home />} />
            <Route path="/" element={<Main />} />
            <Route path="/topics" element={<Topics />}>
              <Route path="/topics/" element={<TopicSearch />} />
              <Route path=":topic" element={<TopicResults />} />
            </Route>
            <Route path="/users" element={<Users />}>
              <Route path="/users/" element={<Backend />} />
            </Route>
          </Routes>
        </main>
      </div>
    </div>
  );
};

const Home = (props) => {
  return (
    <>
      <header>WeaveTracks</header>
      <Main />
    </>
  );
};

const Topics = (props) => {
  return (
    <>
      <header>Search Weave Tracks</header>
      <Outlet />
    </>
  );
};

const Users = () => {
  return (
    <>
      <header>WeaveTracks Backend</header>
      <Outlet />
    </>
  );
};

const TopicResults = () => {
  const [topicPostInfos, setTopicPostInfos] = React.useState([]);
  const [isSearching, setIsSearching] = React.useState(false);
  const { topic } = useParams();
  const navigate = useNavigate();

  const onTopicSearch = (topic) => {
    navigate(`/topics/${topic}`);
  };

  React.useEffect(() => {
    setIsSearching(true);
    setTopicPostInfos([]);
    try {
      getPostInfos(null, topic).then((posts) => {
        setTopicPostInfos(posts);
        setIsSearching(false);
      });
    } catch (error) {
      console.logErrorg(error);
      setIsSearching(false);
    }
  }, [topic]);
  return (
    <>
      <TopicSearch searchInput={topic} onSearch={onTopicSearch} />
      {isSearching && <ProgressSpinner />}
      <Posts postInfos={topicPostInfos} />
    </>
  );
};

function UserResults() {
  const [userPostInfos, setUserPostInfos] = React.useState([]);
  const [isSearching, setIsSearching] = React.useState(false);
  const { addr } = useParams();
  const navigate = useNavigate();

  const onUserSearch = (address) => {
    navigate(`/users/${address}`);
  };

  React.useEffect(() => {
    setIsSearching(true);
    try {
      getPostInfos(addr).then((posts) => {
        setUserPostInfos(posts);
        setIsSearching(false);
      });
    } catch (error) {
      console.logErrorg(error);
      setIsSearching(false);
    }
  }, [addr]);
  return (
    <>
      <UserSearch searchInput={addr} onSearch={onUserSearch} />
      {isSearching && <ProgressSpinner />}
      <Posts postInfos={userPostInfos} />
    </>
  );
}

export default App;
